import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "migrating-from-v0-to-v1"
    }}>{`Migrating from v0 to v1`}</h1>
    <p>{`Unfortunately, we’ve had to introduce a few breaking changes, so we’ve written a migration guide.
In order to ensure as seamless of an upgrade as possible we plan on supporting v0 for the next
12 months so there’s not a huge rush to update (though we’d love for you to ASAP) 📆.`}</p>
    <h2 {...{
      "id": "️-breaking-changes"
    }}>{`⚠️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#pragma"
        }}>{`🚨`}<inlineCode parentName="a">{`@mdx-js/tag`}</inlineCode>{` is replaced by `}<inlineCode parentName="a">{`@mdx-js/react`}</inlineCode>{` and an `}<inlineCode parentName="a">{`mdx`}</inlineCode>{` pragma`}</a>{` 🚨`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mdxprovider"
        }}>{`MDXProvider now merges component contexts when nested`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#react"
        }}>{`React support now requires `}<inlineCode parentName="a">{`>= 16.8`}</inlineCode>{` in `}<inlineCode parentName="a">{`@mdx-js/react`}</inlineCode></a></li>
    </ul>
    <h2 {...{
      "id": "pragma"
    }}>{`Pragma`}</h2>
    <p>{`The MDXTag implementation has been removed with a custom pragma implementation inspired by
`}<a parentName="p" {...{
        "href": "https://emotion.sh/docs/css-prop#jsx-pragma"
      }}>{`Emotion`}</a>{`.
This ensures that transpiled JSX is more readable and that JSX blocks use the same component
as its markdown counterpart.
It also allows MDXProvider to provide global component scope like a `}<inlineCode parentName="p">{`Youtube`}</inlineCode>{` or `}<inlineCode parentName="p">{`Twitter`}</inlineCode>{`
component.`}</p>
    <p>{`The pragma implementation will also cause JSX HTML elements to be rendered with the componen
mapping passed to MDXProvider.
So, the following will result in two identically rendered `}<inlineCode parentName="p">{`h1`}</inlineCode>{`s:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`# Hello, world!

<h1>Hello, world!</h1>
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://mdxjs.com/blog/custom-pragma"
      }}>{`See the blog post for further reading`}</a></p>
    <h2 {...{
      "id": "mdxprovider"
    }}>{`MDXProvider`}</h2>
    <p>{`This shouldn’t affect most usecases, however if you’re nesting component contexts and rely on them not
being merged you will have to use the functional form which allows you to customize the merge.
By ignoring outer context components and returning a new component mapping, you will restore the old behavior:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<MDXProvider components={components}>
  <MDXProvider components={outerComponents => newComponents}>
    {children}
  </MDXProvider>
</MDXProvider>
`}</code></pre>
    <h2 {...{
      "id": "react"
    }}>{`React`}</h2>
    <p>{`Before upgrading to `}<inlineCode parentName="p">{`@mdx-js/mdx@1`}</inlineCode>{`, update your website/application to `}<inlineCode parentName="p">{`react@16.8 react-dom@16.8`}</inlineCode>{` and ensure it works as expected.  Then upgrade to v1.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      